<template>
  <div class="footer">
    <!-- <div class="nav-socials">
      <p>vessels<br />twitter</p>
    </div>
    <div class="nav-socials">
      <p>vessels<br />discord</p>
    </div> -->
    <a href="https://twitter.com/VesselFLOW" target="_blank"><img src="../assets/logos/twitter.png" /></a>
    <a href="https://discord.gg/JA7afH4ECS" target="_blank"><img src="../assets/logos/discord.png" /></a>
    <a href="https://objkt.com/collections/vesselgen0" target="_blank"><img src="../assets/logos/versum-logo.png" /></a>
    <a href="https://vesselsproject.xyz/" target="_blank"><img src="../assets/logos/origin-logo.png" /></a>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
@import "../styles/styles.scss";

.footer {
  display: flex;
  flex-direction: row;
  padding: 0 40px 20px;
  justify-content: center;
  align-items: center;
  img {
    width: 52px;
    @include media(">=tablet") {
      max-width: 62px;
      width: 100%;
    }
  }
}
.footer > * {
  margin: 0 5px;
}
</style>
