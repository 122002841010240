<template>
  <div class="nav-wrapper">
    <div class="nav">
      <!-- <div class="nav-socials">
        <p>vessels<br />twitter</p>
      </div>
      <div class="nav-socials">
        <p>vessels<br />discord</p>
      </div> -->
      <a href="https://twitter.com/VesselFLOW" target="_blank"><img src="../assets/logos/twitter.png" /></a>
      <a href="https://discord.gg/JA7afH4ECS" target="_blank"><img src="../assets/logos/discord.png" /></a>
      <a href="https://objkt.com/collection/vesselgen0" target="_blank"><img src="../assets/logos/versum-logo.png" /></a>
      <a href="https://vesselsproject.xyz/"><img src="../assets/logos/origin-logo.png" /></a>
      <div class="sync">
        <a href="#" class="sync-button" v-if="!saleStore.hasPermissions" @click="saleStore.sync">SYNC</a>
        <a href="#" class="sync-button" v-else @click="saleStore.desync">UNSYNC</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import useSaleStore from '@/stores/useSaleStore';

const saleStore = useSaleStore();
</script>

<style lang="scss">
@import "../styles/styles.scss";
.nav-wrapper {
  position: fixed;
  top: 0;
  background: #000;
  width: 100%;
  z-index: 1;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;
    @include media ('>=390px') {
  justify-content: flex-end;

    }
  img {
    width: 52px;
    @include media ('>=tablet') {
      width: 62px;
    }
  }
  &-socials {
    border: 1px solid #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $heading-font-family;
       height: 50px;
      width: 50px;
    @include media ('>=tablet') {
      height: 60px;
      width: 60px;
      p {
      }
    }
    p {
      font-size: 5px;
    @include media ('>=tablet') {
        font-size: 7px;

    }

    }

  }
  .sync {
    font-family: $heading-font-family;
    margin-left: 40px;
    @include media ('>=tablet') {
    margin-left: 100px;

    }
  }
}
.nav > * {
  margin: 0 5px;
  cursor: pointer;
}
</style>
