<template>
  <div class="header container">
    <img class="header-logo" src="../assets/logos/main-logo.png" />
    <img class="header-images" src="../assets/header-images.jpg" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header {
  padding-top: 150px;
  &-logo {
    max-width: 450px;
    width: 100%;
    margin-bottom: 30px;
  }
}
</style>
