<template>
  <div class="timeline container">
    <h1 class="section-heading">Vessels Timeline</h1>
    <div class="">
      <div class="card">
        <div class="info">
          <h3 id="vessels" class="title">Origins</h3>
          <p>
            Vessels began over a decade ago as an intellectual property and visual development project by artist Jake Gumbleton. A few years later, it started coming to life as a graphic novel (which is still in progress) with writer Drew Dewhirst.
            <br><br>
            Vessels takes place on a post-Earth space colony where only one small fragment of humanity seems to have survived. It explores themes that are prevalent in our world right now and how we cannot escape facing them.
            “Humanity is on the brink. Our last remnants are clinging to existence on a dangerous alien world and we have brought our old demons with us to our new home...”
            <br><br>
            Drops in Season 1 started as mass minting events of 25 pieces in a batch and on-boarded what became the core of the Vessels community.
          </p>
          <img src="../assets/graphic-novel-preview.jpg" class="graphic-novel-preview">
        </div>
      </div>

      <div class="card">
        <div class="info">
          <h3 class="title">Season 1</h3>
          <p class="vessels-gold">
            The advent of Green NFT’s through Hic et Nunc and the Tezos block
            chain has allowed Vessels to evolve into a community driven project.
            The community members, through discord, are now actively engaged
            with world building, fan fiction and creative input to the project.
            We have a whole world to create and we want you to be involved in
            building it!
          </p>
          <div class="hexagon-gallery">
            <div class="hex"><img src="../assets/timeline/genesis.jpg" /></div>

            <div ref="LoopContainer1" class="hex">
              <div
                class="keen-slider__slide"
                v-for="image in hexImages1"
                :key="image"
              >
                <img :src="imageSrc(image)" />
              </div>
            </div>

            <div ref="SliderContainer1" class="hex">
              <div
                class="keen-slider__slide"
                v-for="image in firstSliderImages"
                :key="image"
              >
                <img
                  style="width: 200%; height: auto"
                  src="../assets/timeline/deathjump.jpg"
                />
              </div>
            </div>

            <div ref="LoopContainer2" class="hex">
              <div
                class="keen-slider__slide"
                v-for="image in hexImages2"
                :key="image"
              >
                <img :src="imageSrc(image)" />
              </div>
            </div>

            <div ref="LoopContainer3" class="hex">
              <div
                class="keen-slider__slide"
                v-for="image in hexImages3"
                :key="image"
              >
                <img :src="imageSrc(image)" />
              </div>
            </div>

            <div ref="SliderContainer2" class="hex">
              <div
                class="keen-slider__slide"
                v-for="image in secondSliderImages"
                :key="image"
              >
                <img
                  style="width: 200%; height: auto"
                  src="../assets/timeline/s1-1.png"
                />
              </div>
            </div>

            <div ref="LoopContainer4" class="hex">
              <div
                class="keen-slider__slide"
                v-for="image in hexImages4"
                :key="image"
              >
                <img :src="imageSrc(image)" />
              </div>
            </div>

            <div ref="LoopContainer5" class="hex">
              <div
                class="keen-slider__slide"
                v-for="image in hexImages5"
                :key="image"
              >
                <img :src="imageSrc(image)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="info">
          <h3 class="title">Season 2</h3>
          <p class="dragon">
            Season 2 introduced spaceships, fauna, curiosities and 
            story beats from the Vessel universe along with lots more 
            characters and our ‘Alpha series’ crossover pieces allowing 
            characters from other NFT projects to come and visit the colony.
            <br><br>
            All of the drops in S2 were handled as discord raffles/auctions. 
            The auctions were some of the best fun we have had.  
            Collectors got really into ‘auction by gif’ in discord.
          </p>
          <div class="gallery">
            <div ref="LoopContainer6" class="gallery-left">
              <div
                class="keen-slider__slide"
                v-for="image in galleryImages1"
                :key="image"
              >
                <img :src="imageSrc(image)" />
              </div>
            </div>

            <div ref="LoopContainer7" class="gallery-right">
              <div
                class="keen-slider__slide gallery-item"
                v-for="image in galleryImages2"
                :key="image"
              >
                <img :src="imageSrc(image)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="info">
          <h3 class="title">Gen-▢</h3>

          <p class="vessels-gold">
            <b>Gen-O</b> is a collection of 5000 generative Vessels containing 
            hundreds of design elements both native to the ‘Vessels Universe’ 
            and to ‘Old Earth’.  It may also contain a smattering of Tezos NFT 
            easter egg references :)
          </p>
          <p>
            The youth of the colony exist devoid of any culture to call their 
            own- they never knew Old Earth and have little to no aesthetic 
            identity as pioneer colonist offspring. In that context they steal 
            their fashion from anything they like- so all of old earth's
            cultural history can be magpied, alongside things from their own
            fledgling society, their new alien home and all it has to offer.
            The result is a kaleidoscope of alien, scifi, old earth culture
            and teenage flamboyance.
          </p>
          <img style="margin: 20px 0" src="../assets/timeline-characters.jpg" />
        </div>
      </div>
      <div class="card">
        <div class="info">
          <h3 class="title">Season 3 and beyond</h3>
          <p class="dragon">
            Vessels will continue on into Season 3 once Gen 0 has dropped. 
            We have lots of exciting ideas but prefer to maintain the organic
            nature of the project so far. We are so hyped to build this universe:
            with the central story arc in the form of a graphic novel and then
            a larger world built with the community around this core.
            This is just the beginning!
          </p>
          <img
            style="margin: 20px auto; width: 100%"
            src="../assets/timeline-last-image.jpg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useKeenSlider } from "keen-slider/vue.es";
import "keen-slider/keen-slider.min.css";

const animation = { duration: 43000, easing: (t) => t };
export default {
  name: "Timeline",
  setup() {
    const hexImages1 = [
      "v1.jpg",
      "v2.jpg",
      "v3.jpg",
      "v4.jpg",
      "v5.jpg",
      "v6.jpg",
    ];
    const hexImages2 = ["v7.jpg", "v8.jpg", "v9.jpg", "v10.jpg"];
    const hexImages3 = ["v11.jpg", "v12.jpg", "v13.jpg", "v14.jpg"];
    const hexImages4 = [
      "collab1.jpg",
      "collab2.jpg",
      "collab3.jpg",
      "collab4.jpg",
    ];
    const hexImages5 = ["v15.jpg", "v16.jpg", "v17.jpg", "v18.jpg"];
    const secondSliderImages = ["s1-1.png"];
    const firstSliderImages = ["deathjump.jpg"];
    const galleryImages1 = [
      "v22.jpg",
      "v23.jpg",
      "v24.jpg",
      "v25.jpg",
      "v26.jpg",
      "v27.jpg",
      "v28.jpg",
      "v34.jpg",
    ];
    const galleryImages2 = [
      "v29.jpg",
      "v30.jpg",
      "v31.jpg",
      "v32.jpg",
      "v33.jpg",
      "v35.jpg",
      "v36.jpg",
    ];

    const imageSrc = (image) => require(`../assets/timeline/${image}`);

    const loop = {
      loop: true,
      defaultAnimation: {
        duration: 0,
      },
    };

    const loopDuration = (duration) => {
      return [
        (slider) => {
          let timeout;
          function clearNextTimeout() {
            clearTimeout(timeout);
          }
          function nextTimeout() {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              slider.next();
            }, duration);
          }
          slider.on("created", () => {
            nextTimeout();
          });
          slider.on("dragStarted", clearNextTimeout);
          slider.on("animationEnded", nextTimeout);
          slider.on("updated", nextTimeout);
        },
      ];
    };

    // cycle through images
    const [LoopContainer1] = useKeenSlider(loop, loopDuration(2500));
    const [LoopContainer2] = useKeenSlider(loop, loopDuration(2500));
    const [LoopContainer3] = useKeenSlider(loop, loopDuration(2500));
    const [LoopContainer4] = useKeenSlider(loop, loopDuration(2500));
    const [LoopContainer5] = useKeenSlider(loop, loopDuration(2500));
    const [LoopContainer6] = useKeenSlider(loop, loopDuration(300));
    const [LoopContainer7] = useKeenSlider(loop, loopDuration(2000));

    //slide through images
    const slider = {
      loop: true,
      renderMode: "performance",
      drag: false,
      created(s) {
        s.moveToIdx(-5, true, animation);
      },
      updated(s) {
        s.moveToIdx(s.track.details.abs - 5, true, animation);
      },
      animationEnded(s) {
        s.moveToIdx(s.track.details.abs - 5, true, animation);
      },
    };

    const [SliderContainer1] = useKeenSlider(slider);
    const [SliderContainer2] = useKeenSlider(slider);

    return {
      hexImages1,
      hexImages2,
      hexImages3,
      hexImages4,
      hexImages5,
      firstSliderImages,
      secondSliderImages,
      galleryImages1,
      galleryImages2,
      imageSrc,
      SliderContainer1,
      SliderContainer2,

      LoopContainer1,
      LoopContainer2,
      LoopContainer3,
      LoopContainer4,
      LoopContainer5,
      LoopContainer6,
      LoopContainer7,
      // SlideContainer,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";
.graphic-novel-preview {
  max-width: 300px;
  margin: 0 auto;
  @include media('>=tablet') {
    max-width: 450px;
  }
}

.timeline {
  padding: 40px;
  p {
    font-size: 18px;
  }
  @include media(">=tablet") {
    padding: 75px;
    p {
      font-size: 22px;
    }
  }
}

/* Card container */
.card {
  position: relative;
  max-width: 1375px;
  width: 100%;
  // margin: 0 25px;
  margin: auto;
  width: 100%;
  @include media(">=tablet") {
    // margin: 0 50px;
  }
}

/* setting padding based on even or odd */
.card:nth-child(odd) {
  padding: 30px 0 30px 30px;
  @include media(">=tablet") {
    padding: 60px 0 60px 60px;
  }
}
.card:first-child {
  padding-top: 30px;
}
.card:nth-child(even) {
  padding: 30px 30px 30px 0;
  @include media(">=tablet") {
    padding: 60px 60px 60px 0;
  }
}
/* Global ::before */
.card::before {
  content: "";
  position: absolute;
  width: 50%;
  border: solid #fff;
}

/* Setting the border of top, bottom, left */
.card:nth-child(odd)::before {
  left: 0px;
  top: -5px;
  bottom: -5.5px;
  border-width: 5px 0 5px 5px;
  border-radius: 50px 0 0 50px;
}

/* Setting the top and bottom to "-5px" because earlier it was out of a pixel in mobile devices */
@media only screen and (max-width: 400px) {
  .card:nth-child(odd)::before {
    top: -5px;
    bottom: -5px;
  }
}

/* Setting the border of top, bottom, right */
.card:nth-child(even)::before {
  right: 0;
  top: 0;
  bottom: 0;
  border-width: 5px 5px 5px 0;
  border-radius: 0 50px 50px 0;
}

/* Removing the border if it is the first card */
.card:first-child::before {
  border-top: 0;
  border-top-left-radius: 0;
  top: 150px;
}

/* Removing the border if it is the last card  and it's odd */
.card:last-child:nth-child(odd)::before {
  border-bottom: 0;
  border-bottom-left-radius: 0;
}

/* Removing the border if it is the last card  and it's even */
.card:last-child:nth-child(even)::before {
  border-bottom: 0;
  border-bottom-right-radius: 0;
}

// shortening the last border
.card:last-child::before {
  bottom: 370px;
  @include media(">=400px") {
    bottom: 345px;
  }
  @include media(">=484px") {
    bottom: 300px;
  }
  @include media(">=tablet") {
  bottom: 330px;

  }

  // @include media(">=910px") {
  //   bottom: 330px;
  // }
}
.info {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

/* Title of the card */
.title {
  color: #ae841a;
  margin: 0;
  position: relative;
}

/* Timeline dot  */
#vessels::before {
  // content: url("../assets/logos/origin-logo.png");
  content: "";
  background: url("../assets/logos/origin-logo.png");
  background-size: 50px 50px;
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  left: -62px;
  top: 80px;
  // background: white;
  // border-radius: 999px;
  // border: 3px solid #fff;
  @include media(">=tablet") {
    background-size: 65px 65px;
    width: 65px;
    height: 65px;
    left: -100px;
  }
}

.vessels-gold::before {
  content: "";
  background: url("../assets/logos/vessels-logo.png");
  background-size: 50px 50px;
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  right: -25px;
  top: 50%;

  @include media(">=tablet") {
    background-size: 65px 65px;

    width: 65px;
    height: 65px;
    right: -30px;
  }
}

.dragon::before {
  // content: url("../assets/logos/origin-logo.png");
  content: "";
  background: url("../assets/logos/dragon-logo.png");
  background-size: 50px 50px;
  position: absolute;
  display: block;
  width: 50px;
  height: 50px;
  left: -22px;
  top: 50%;
  // background: white;
  // border-radius: 999px;
  // border: 3px solid #fff;
  @include media(">=tablet") {
    background-size: 65px 65px;
    width: 65px;
    height: 65px;
    left: -33px;
  }
}

.dragon:last-of-type::before {
  // top: 620px;
}

/* align titles */
.card:nth-child(even) > .info > .title {
  text-align: right;
}
.card:nth-child(even) > .info > p {
  text-align: right;
}
.card:nth-child(odd) > .info > .title {
  text-align: left;
}
/* setting dot to the left if the card is odd */
.card:nth-child(odd) > .info > .title::before {
  left: -75px;
}

/* setting dot to the right if the card is odd */
.card:nth-child(even) > .info > .title::before {
  right: -75px;
}

// HEX
.hexagon-gallery {
  box-sizing: border-box;
  margin: auto;
  margin-top: 50px;
  max-width: 1000px;
  // width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 135px;
  grid-gap: 5px;
  // display: flex;
  // flex-wrap: wrap;
  // flex-direction: column;
  padding-bottom: 45px;
  overflow: hidden;
  //   max-width: 90%;
  // max-height: 110%;
  img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
  }
  @include media(">=phone-lg") {
    // width: inherit;
  }
}

.hex {
  display: flex;
  position: relative;
  width: 123px;
  height: 151px;
  background-color: #424242;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);

  @include media(">=650px") {
    width: 145px;
    height: 170px;
  }
}

.hex:first-child {
  grid-row-start: 1;
  grid-column: 2 / span 2;
  // @include media(">=phone-lg") {
  //   grid-row-start: 1;
  //   grid-column: 2 / span 2;
  // }
  // @include media(">=tablet-lg") {
  //   grid-row-start: 1;
  //   grid-column: 2 / span 2;
  // }
}

.hex:nth-child(2) {
  grid-row-start: 1;
  grid-column: 4 / span 2;
  // @include media(">=phone-lg") {
  //   grid-row-start: 1;
  //   grid-column: 4 / span 2;
  // }
  // @include media(">=tablet-lg") {
  //   grid-row-start: 1;
  //   grid-column: 4 / span 2;
  // }
}

.hex:nth-child(3) {
  grid-row-start: 2;
  grid-column: 3 / span 2;

  @include media(">=phone-lg") {
    grid-row-start: 1;
    grid-column: 6 / span 2;
  }
  @include media(">=tablet-lg") {
    grid-row-start: 1;
    grid-column: 6 / span 2;
  }
}

.hex:nth-child(4) {
  grid-row-start: 3;
  grid-column: 2 / span 2;
  @include media(">=phone-lg") {
    grid-row-start: 2;
    grid-column: 3 / span 2;
  }
  @include media(">=tablet-lg") {
    grid-row-start: 1;
    grid-column: 8 / span 2;
  }
}

.hex:nth-child(5) {
  grid-row-start: 3;
  grid-column: 4 / span 2;
  @include media(">=phone-lg") {
    grid-row-start: 2;
    grid-column: 5 / span 2;
  }
  @include media(">=tablet-lg") {
    grid-row-start: 1;
    grid-column: 10 / span 2;
  }
}

.hex:nth-child(6) {
  grid-row-start: 4;
  grid-column: 3 / span 2;
  @include media(">=phone-lg") {
    grid-row-start: 3;
    grid-column: 2 / span 2;
  }
  @include media(">=tablet-lg") {
    grid-row-start: 2;
    grid-column: 3 / span 2;
  }
}

.hex:nth-child(7) {
  grid-row-start: 5;
  grid-column: 2 / span 2;
  @include media(">=phone-lg") {
    grid-row-start: 3;
    grid-column: 4 / span 2;
  }
  @include media(">=tablet-lg") {
    grid-row-start: 2;
    grid-column: 5 / span 2;
  }
}

.hex:nth-child(8) {
  grid-row-start: 5;
  grid-column: 4 / span 2;
  @include media(">=phone-lg") {
    grid-row-start: 3;
    grid-column: 6 / span 2;
  }
  @include media(">=tablet-lg") {
    grid-row-start: 2;
    grid-column: 7 / span 2;
  }
}

.gallery {
  box-sizing: border-box;
  margin: auto;
  margin-top: 50px;
  // max-width: 1116px;
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // // grid-auto-rows: 135px;
  // grid-template-rows: auto;
  // grid-gap: 5px;
  display: flex;
  padding-bottom: 10px;
  // max-width: 400px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  flex-wrap: wrap;
  img {
    // width: 100%;
    // height: auto;
    // display: block;
    // object-fit: cover;
    max-width: 750px;
  }
}
.gallery-left,
.gallery-right {
  display: flex;
  flex-direction: row;
  // flex-basis: 50%;
  // position: relative;
  // height: 100%;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  margin: 20px;
  // width: 100%;
  // .gallery-item {
  //   display: flex;
  // }

  // display: flex;
  // // flex-direction: row;
  // // width: 100%;
  // position: relative;
  // width: 145px;
  // height: 170px;
  // .gallery-left, .gallery-right {
  //   max-width: 200px;
  //   // flex-basis: 50%;
  //   // width: 100%;
  // }
}

.gallery-left {
  max-width: 300px;
  width: 100%;
  height: auto;
  max-height: 100%;

  img {
    max-width: 300px;
  }
}

.gallery-right {
  max-width: 750px;
  width: 100%;
  height: auto;
  max-height: 100%;

  img {
    max-width: 750px;
  }
}

// .gallery-left:first-child {
//   grid-row-start: 1;
//   grid-column: 2 / span 2;
// }

// .gallery-left:nth-child(2) {
//   grid-row-start: 1;
//   grid-column: 4 / span 2;
// }
</style>
