
import useSaleStore from '@/stores/useSaleStore';
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'Countdown',
  props: ['to'],
  setup: (props) => {
    // Calculate difference between current time and :to property
    // Left fill seconds with zeros
    const zfill1 = (num: number, size: number) => {
      let v = num.toString();
      while (v.length < size) v = "0" + v;
      return v;
    }
    const SaleStore = useSaleStore();

    const difference = computed(() => {
      if (props.to) {
        const diff = props.to.diff(SaleStore.currentTime, ['hours', 'minutes', 'seconds']);
        const diffObj = diff.toObject();
        if (SaleStore.currentTime > props.to) {
          diffObj.hours = 0;
          diffObj.minutes = 0;
          diffObj.seconds = 0;
        }
        Object.keys(diffObj).forEach(k => diffObj[k] = zfill1(Math.round(diffObj[k]), 2));
        return diffObj;
      }
      return undefined;
    });
    const countdownFinished = computed(() => difference.value && difference.value.seconds && difference.value.seconds < 0);
    return {
      difference,
      countdownFinished,
    }
  }
})
