<template>
  <div class="faq container">
    <ul class="faq-box">
      <li>Price? <span style="color: #ae841a">15 tezos</span></li>
      <li>How many? <span style="color: #ae841a">5000 Editions</span></li>
      <li>Schedule:</li>
      <li style="margin-left: 50px;">OG Sale Tue Feb 15 (15 editions max)</li>
      <li style="margin-left: 50px;">Presale Wed Feb 16 (5 editions max)</li>
      <li style="margin-left: 50px;">Public Sale Thur Feb 17 (10 editions max)</li>
      <li style="margin-left: 50px;">Reveal Friday Feb 18</li>
    </ul>
    <ul class="faq-box">
      <li>How do you know it's fair?</li>
      <li class="indent">
        The contract includes a provenance hash. This is a SHA-256 of an IPFS
        directory that refers to a fingerprint of the tokens and the art. It
        means anyone can verify that we didn't change the tokens after minting
        them. Also, when the sale starts, the id of each mint is randomized.
      </li>
      <li>Where to trade on secondary?</li>
      <li class="indent">Buy and sell secondary sales on <a style="text-decoration: underline;" href="https://objkt.com/collections/vesselgen0" target="_blank">Objkt</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FAQ",
};
</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";

li {
  text-decoration: none;
  // list-style: none;
  text-align: left;
  margin: 20px 0;
  margin-left: 25px;
}
.faq {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 350px;
  width: 100%;
  &-box {
    flex-basis: 100%;
    border: 2px solid #fff;
    border-radius: 10px;
    margin: 20px 25px;
    padding: 25px;
    .indent {
      margin-left: 50px;
    }
    @include media(">=tablet") {
      flex-basis: 46%;
      margin: 20px 10px;
      padding: 40px;
      // li {
      //   margin-left: 25px;
      // }
    }
    @include media(">=1150px") {
      flex-basis: 40%;
      margin: 20px 30px;
      padding: 50px;
    }
  }
}
</style>
