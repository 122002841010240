import { BeaconWallet } from '@taquito/beacon-wallet';
import { ContractAbstraction, TezosToolkit, Wallet, WalletContract } from '@taquito/taquito';
import { until } from '@vueuse/core';
import BigNumber from 'bignumber.js';
import { DateTime } from 'luxon';

import { defineStore } from 'pinia';
import { ref, Ref, toRaw, watch, watchEffect } from 'vue';

interface IContractStorage {
  sale: {
    soldAmount: number,
    saleSupply: number,
    startTime: string,
    ogsaleStartTime: string,
    ogsaleDuration: string,
    presaleStartTime: string,
    presaleDuration: string,
  }
}

const getRandomNode = () => {
  const nodes = [
    'https://mainnet.api.tez.ie',
    'https://mainnet.tezos.marigold.dev/'
  ];
  const node = nodes[Math.floor(Math.random() * nodes.length)];

  return node;
}

const wallet = new BeaconWallet({
  name: 'Vessels Gen-0',
  // @ts-ignore
  // preferredNetwork: 'hangzhounet',
});
const tezos = new TezosToolkit(getRandomNode());
tezos.setProvider({ config: { confirmationPollingTimeoutSecond: 300 * 5 }})

const contract: Ref<WalletContract|undefined> = ref();
const storage: Ref<undefined|IContractStorage> = ref(undefined);
// When contract is set, load the contract storage
const refreshStorage = (): Promise<void> => {
  return new Promise((res, rej) => {
    if (contract.value) {
      contract.value.storage()
        .catch(e => {
          console.log(e)
          rej();
        })
        .then((s) => {
          storage.value = s as IContractStorage;
          res();
        });
    }
  });
}
watchEffect(() => {
  if (contract.value) {
    console.log('Contract: ', contract.value)
    refreshStorage();
  }
});

export enum SaleStates {
  OG_SALE = 'OG Sale',
  PRESALE = 'Presale',
  PUBLIC_SALE = 'Public Sale',
  LOADING = 'Loading',
  COUNTDOWN = 'Countdown',
}

// main is the name of the store. It is unique across your application
// and will appear in devtools
const useSaleStore = defineStore('main', {
  state: () => ({
    isSyncing: false,
    userAddress: undefined as string|undefined,

    startDate: undefined as DateTime|undefined,
    presaleDate: undefined as DateTime|undefined,
    ogStartDate: undefined as DateTime|undefined,

    currentTime: DateTime.local(),
    numberMinted: undefined as number|undefined,
    maxMintable: 5000,
    mutezPerMint: 15000000,
    mintOpPending: false,
  }),
  getters: {
    hasPermissions(): boolean {
      return this.userAddress !== undefined;
    },
    userAddressShort(): string|undefined {
      return this.userAddress ? `${this.userAddress.substring(0, 5)}...${this.userAddress.slice(-5)}` : undefined
    },
    saleState(): SaleStates {
      if (this.startDate && this.presaleDate && this.ogStartDate) {
        const ogDiff = this.ogStartDate.diff(this.currentTime, 'seconds').seconds;
        const presaleDiff = this.presaleDate.diff(this.currentTime, 'seconds').seconds;
        const publicsaleDiff = this.startDate.diff(this.currentTime, 'seconds').seconds
        if (publicsaleDiff < 0) {
          return SaleStates.PUBLIC_SALE
        }
        if (presaleDiff < 0) {
          return SaleStates.PRESALE
        }
        if (ogDiff < 0) {
          return SaleStates.OG_SALE
        }
        return SaleStates.COUNTDOWN;
      }

      return SaleStates.LOADING;
    },
  },
  actions: {
    setUserAddress(addr: string) {
      this.userAddress = addr;
    },
    setFromStorage(storage: IContractStorage) {
      const data = storage.sale;
      this.maxMintable = data.saleSupply;
      this.ogStartDate = DateTime.fromISO(data.ogsaleStartTime);
      this.presaleDate = DateTime.fromISO(data.presaleStartTime);
      this.startDate = DateTime.fromISO(data.startTime);
      this.numberMinted = data.soldAmount;
    },
    desync() {
      console.log('Desyncing wallet');
      this.isSyncing = true;
      Promise.all([wallet.disconnect(), wallet.clearActiveAccount()]).then(() => {
        this.userAddress = undefined;
      }).finally(() => {
        this.isSyncing = false;
      });
    },
    sync(): Promise<void> {
      return new Promise((res, rej) => {
        this.isSyncing = true;
        wallet.client.requestPermissions({
          // network: {
          //   // @ts-expect-error; incomplete types
          //   // type: 'hangzhounet',
          // },
        })
        .catch((e) => {
          console.error(e)
          rej(e);
        })
        .then(() => {
          tezos.setWalletProvider(wallet);
          wallet.getPKH().then(pkh => {
            this.userAddress = pkh;
            console.log('BeaconWallet connected', this.hasPermissions);
            res();
          })
        }).finally(() => {
          this.isSyncing = false;
        });
      })
    },

    async autosync() {
      const activeAccount = await wallet.client.getActiveAccount();
      if (!activeAccount) {
        await this.sync();
      }
    },

    async mint(count: number) {
      const amount = count * this.mutezPerMint;
      if (amount < 1) {
        return;
      }

      try {
        await this.autosync();
        if (contract.value === undefined) {
          await until(contract).toMatch(c => c !== undefined, { timeout: 50000 });
        }
        const c = contract.value as ContractAbstraction<Wallet>;
      
        const op = await c.methods.mint(count).send({
          amount: amount,
          mutez: true,
          storageLimit: 3000,
        });
        this.mintOpPending = true;
        const result = await op.confirmation();
        console.log(result);
      } catch (e) {
        console.error(e);

        // @ts-ignore
        const errorType: string = e.data.find(d => d.with !== undefined && d.with.string !== undefined).with.string;
        this.handleError(errorType as ErrorTypes, e);
        return;
      }
      const alertDetails: ICustomAlertDetails = {
        type: 'success',
        title: 'Congratulations',
        message: `You've minted ${count} Vessel Gen-0 tokens!`
      }
        const ev = new CustomEvent('customalert', { detail: alertDetails });
        window.dispatchEvent(ev);

      this.mintOpPending = false;
    },

    handleError(errorType: ErrorTypes, err?: unknown) {
      console.log({ errorType })
      const errorLookup: Record<ErrorTypes, string> = {
        'NOT_ON_PRESALE_LIST': 'This wallet is not on the presale whitelist.',
        'NOT_ON_OG_SALE_LIST': 'This wallet is not on the OG sale whitelist.',
        'SALE_PAUSED': 'The sale is currently paused, please check the discord for updates.',
        'SOLD_OUT': 'We\'ve sold out of Vessel Gen-0 tokens.',
        'INVALID_AMOUNT': 'Something went wrong.  Please get in touch with us via the discord channel.',
        'SALE_NOT_STARTED': 'The sale has not started yet.  This can sometimes occur when the blockchain is using an outdated time signature.  Please try again in 10-20 seconds.',
        'MAX_MINT_PER_WALLET_EXCEEDED': 'You\'ve already minted 10 tokens with this wallet.  Please sync another wallet if you\'d like to mint more tokens.',
        'MAX_MINT_PER_BLOCK_EXCEEDED': 'You can only mint 10 tokens at a time, please wait for the transaction to finish before minting more.'
      }
      const errorMessage = errorLookup[errorType];
      const alertDetails: ICustomAlertDetails = {
        type: 'error',
        title: 'There was an error while minting',
        message: '',
      }
      alertDetails.message = errorMessage ? errorMessage : `An unknown error occured.  Please copy the entirety of this error message and get in contact with us via the discord channel. ${JSON.stringify(err)}`
      const ev = new CustomEvent('customalert', { detail: alertDetails });
      window.dispatchEvent(ev);
    }
  },
});

export default useSaleStore;

export type ErrorTypes = 'NOT_ON_PRESALE_LIST'|'SALE_PAUSED'|'SALE_NOT_STARTED'|'SOLD_OUT'|'NOT_ON_OG_SALE_LIST'|'NOT_ON_PRESALE_LIST'|'INVALID_AMOUNT'|'MAX_MINT_PER_WALLET_EXCEEDED'|'MAX_MINT_PER_BLOCK_EXCEEDED';
export interface ICustomAlertDetails {
  title: string;
  type: 'success'|'error';
  message: string;
}


// This should persist the user's session if they're logged into their beacon wallet avoiding crashing beacon infrastructure.
document.addEventListener('DOMContentLoaded', () => {
  // Auto set wallet from local storage
  tezos.setWalletProvider(wallet);
  const ss = useSaleStore();

  wallet.getPKH()
    .catch(() => { console.warn('Wallet not synced yet')})
    .then(pkh => {
      console.log(`pkh: ${pkh}`)
      if (pkh !== undefined) {
        ss.setUserAddress(pkh)
      }
    });

  watch(storage, storage => {
    if (storage) {
      salesStore.setFromStorage(toRaw(storage));
    }
  })

  tezos.wallet.at('KT1U1GDQDE7C9DNfE9iSojsKfWf5zUXdSVde').then((c) => {
    contract.value = c;
  }).catch(e => console.error(e))


  // Update current time
  const salesStore = useSaleStore();

  setInterval(() => {
    const dt = DateTime.local();
    salesStore.currentTime = dt;
  }, 1000)
});
