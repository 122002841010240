import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import { createPinia } from 'pinia'

const app = createApp(App);

app.use(VueGtag, {
  config: { id: 'G-VV1D3NHXKV' }
});
app.use(createPinia());

app.mount('#app');
