<template>
  <div :class="{
    minting: true, 
    ['minting--loader']: saleStore.mintOpPending || saleStore.saleState === SaleStates.LOADING,
    container: true,
    }">
    <div class="minting-bg">
      <img
        v-if="mobileView"
        class="minting-bg-image"
        src="../assets/minting-background-sm-2.png"
      />
      <img
        v-else
        class="minting-bg-image"
        src="../assets/minting-background-md.jpg"
      />
      <template v-if="saleStore.saleState !== SaleStates.LOADING">
        <div class="minting-bottom" v-if="saleStore.saleState !== SaleStates.COUNTDOWN">
          <div class="minting-text">
            <p class="smaller">Now Minting</p>
            <p v-if="saleStore.saleState === SaleStates.OG_SALE" >OG Sale</p>
            <p v-else-if="saleStore.saleState === SaleStates.PRESALE" >Presale</p>
            <p v-else-if="saleStore.saleState === SaleStates.PUBLIC_SALE" >Public Sale</p>
          </div>
          <div class="minting-buttons">
            <img
              v-show="rollover1"
              @mouseleave="rollover1 = false"
              src="../assets/buttons/mint1-rollover.png"
              @click="saleStore.mint(1)"
            />
            <img
              v-show="!rollover1"
              @mouseenter="rollover1 = true"
              src="../assets/buttons/mint1.png"
              @click="saleStore.mint(1)"
            />
            <img
              v-show="rollover2"
              @mouseleave="rollover2 = false"
              src="../assets/buttons/mint5-rollover.png"
              @click="saleStore.mint(5)"
            />
            <img
              v-show="!rollover2"
              @mouseenter="rollover2 = true"
              src="../assets/buttons/mint5.png"
              @click="saleStore.mint(5)"
            />
            <img
              v-show="rollover3"
              @mouseleave="rollover3 = false"
              src="../assets/buttons/mint10-rollover.png"
              @click="saleStore.mint(10)"
            />
            <img
              v-show="!rollover3"
              @mouseover="rollover3 = true"
              src="../assets/buttons/mint10.png"
              @click="saleStore.mint(10)"
            />
          </div>
          <div class="minting-text">
            <p>{{saleStore.numberMinted}}/{{saleStore.maxMintable}}</p>
            <p class="smaller">MINTED</p>
          </div>
        </div>
        <div class="minting-bottom" v-else>
          <div style="width: 100%; padding-bottom: 20px;">
          <p class="minting-copy">
            OG Presale starts in 
          </p>
          <h3 class="minting-countdown"><Countdown :to="saleStore.ogStartDate" /></h3>
          <p class="minting-copy">Check FAQ for sale schedule</p>
          </div>
        </div>
      </template>
    </div>
    <div v-if="saleStore.mintOpPending || saleStore.saleState === SaleStates.LOADING" class="minting-loader">
      <div class="lds-ripple"><div></div><div></div></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, } from "vue";
import Countdown from '../components/Countdown.vue';
import useSaleStore, { SaleStates } from '../stores/useSaleStore'

const saleStore = useSaleStore()

const rollover1 = ref(false);
const rollover2 = ref(false);
const rollover3 = ref(false);

const mobileView = ref(false);

const handleView = () => {
  mobileView.value = window.innerWidth < 600;
};

handleView();
onMounted(() => window.addEventListener("resize", handleView));
onUnmounted(() => window.removeEventListener("resize", handleView));

</script>

<style lang="scss" scoped>
@import "../styles/styles.scss";

.minting {
  position: relative;
  &-loader {
    z-index: 10000;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &-bg {
    position: relative;
  }
  &-bottom {
    .minting--loader & {
      opacity: 0.5;
      pointer-events: none;
    }
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: $heading-font-family;
    z-index: 0;
    padding: 0 20px;
    height: 100%;
    flex-direction: column;
    // flex-wrap: wrap;
    // margin-bottom: 50px;
    p {
    }
    @include media(">=tablet") {
      flex-direction: row;
    }
    @include media(">=1030px") {
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 0;
      flex-direction: row;
    }
  }
  &-text {
    margin: 20px 0;
      min-width: 230px;
    @include media(">=tablet") {
      margin: 35px 0;
      p {

        font-size: 24px;
      }
    }
    p {
      margin: 0;
      text-align: center;
    }
    .smaller {
      font-size: 12px;
      @include media ('>=tablet') {
        font-size: 16px;
      }
    }
  }
  &-buttons {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 20px;
    @include media(">=tablet") {
      margin-bottom: 15px;
      flex-direction: row;
    }
    img {
      max-width: 150px;
      min-width: 130px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 10px;
      cursor: pointer;
      width: 100%;
      height: auto;
      @include media(">=tablet") {
        max-width: 150px;
        width: 100%;
      }
    }
  }

  &-countdown {
    margin: 7px 0 38px;
    font-size: 28px;
    @include media(">=tablet") {
      font-size: 38px;
    }
    @include media(">=desktop") {
      font-size: 42px;
    }
  }

  &-copy {
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin: 20px 0;
    @include media(">=tablet") {
      font-size: 22px;
    }
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

</style>
